import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchTemplates } from '../ApiService/ApiService';
import './TemplateList.css';
import { useTheme } from '../ThemeContext';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const TemplateList = () => {
    const { theme } = useTheme();
    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1); // Start with page 1
    const [hasMore, setHasMore] = useState(true); // To track if more data is available
    const scrollContainerRef = useRef(null); // Ref for the scrollable container
    const navigate = useNavigate();

    const fetchTemplateData = useCallback(async () => {
        if (!hasMore) return; // Prevent fetching if there's no more data
        setLoading(true);
        try {
            const templatesData = await fetchTemplates({ page, limit: 10 }); // Pass page and limit
            console.log("Fetched templates:", templatesData);
            setTemplates((prev) => [...prev, ...templatesData]);
            if (templatesData.length < 10) setHasMore(false); // No more data if less than 10 items fetched
        } catch (err) {
            if (err.response && err.response.status === 403) {
                navigate('/login');
            } else {
                setError(err);
                console.error('Error fetching templates:', err);
            }
        } finally {
            setLoading(false);
        }
    }, [page, hasMore, navigate]);

    useEffect(() => {
        fetchTemplateData();
    }, [fetchTemplateData]);

    const handleScroll = () => {
        const container = scrollContainerRef.current;
        if (container) {
            const { scrollTop, scrollHeight, clientHeight } = container;
            // Check if near the bottom and fetch more data if available
            if (scrollHeight - scrollTop <= clientHeight + 50 && hasMore && !loading) {
                console.log('Loading more data');
                setPage((prev) => prev + 1);
            }
        }
    };

    // Recalculate scroll state on window resize
    useEffect(() => {
        const handleResize = () => {
            const container = scrollContainerRef.current;
            if (container && container.scrollHeight > container.clientHeight) {
                console.log('Scroll container updated after resize');
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleRowClick = (template) => {
        if (template.status && template.status.toLowerCase() === 'closed') {
            navigate(`/main/template_report?campaign_id=${template.id}`);
        } else {
            navigate(`/main/send-template?template_id=${template.id}`);
        }
    };


    const getStatusCount = (statusCounts, status) => {
        if (!Array.isArray(statusCounts)) {
            return 0; // Return 0 if statusCounts is undefined or not an array
        }
        const statusObj = statusCounts.find((sc) => sc.status === status);
        return statusObj ? statusObj.count : 0; // Return the count if found, else return 0
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Extract and format components
        const day = date.getDate().toString().padStart(2, '0'); // 2-digit day
        const month = date.toLocaleString('en-US', { month: 'short' }); // Short month name
        const year = date.getFullYear(); // Full year
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0'); // 2-digit minutes
        const ampm = hours >= 12 ? 'PM' : 'AM'; // AM or PM
        hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 for midnight/noon

        // Combine the formatted components
        return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
    };
    return (
        <>
            {error && <p className="error">ERROR: {error.message}</p>}
            <div className="reccampsu">
                <h5 style={{ position: 'sticky', marginTop: '10px' }}>Recent Campaigns</h5>
                <div
                    className="scroll-container"
                    ref={scrollContainerRef}
                    style={{ overflowY: 'auto', height: '750px', marginTop: '0px' }}
                    onScroll={handleScroll}
                >
                    <table className={`template-table`}>
                        <thead style={{ position: 'sticky', top: '1px' }} className='tabheadert'>
                            <tr>
                                <th rowSpan="2">Campaign ID</th>
                                <th rowSpan="2">Campaign Date</th>
                                <th rowSpan="2">WhatsApp Template</th>
                                <th className="status-column">Read</th>
                                <th className="status-column">Sent</th>
                                <th className="status-column">Delivered</th>
                                <th className="status-column">Failed</th>
                                <th className="status-column">Pending</th>
                                <th className="status-column"  rowSpan="2">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.length > 0 ? (
                                templates.map((template, index) => {
                                    console.log('Template:', template); // Log the template object
                                    return (
                                        <tr key={`${template.id}-${index}`} onClick={() => handleRowClick(template)}>
                                            <td>{template.id}</td>
                                            <td>{formatDate(template.created_at)}</td>
                                            <td>
                                                {template.whatsapp_template_name ? (
                                                    template.whatsapp_template_name
                                                ) : (
                                                    <span style={{ color: 'red' }}>Not Selected</span>
                                                )}
                                            </td>
                                            <td className="status-column">{getStatusCount(template.statusCounts, 'read')}</td>
                                            <td className="status-column">{getStatusCount(template.statusCounts, 'sent')}</td>
                                            <td className="status-column">{getStatusCount(template.statusCounts, 'delivered')}</td>
                                            <td className="status-column">{getStatusCount(template.statusCounts, 'failed')}</td>
                                            <td className="status-column">{getStatusCount(template.statusCounts, 'created')}</td>

                                            <td className="status-column" style={{ width: "10%" }}>
                                                <span
                                                    className={`badge ${template.status === 'closed'
                                                            ? 'badge-closed'
                                                            : template.status === 'created'
                                                                ? 'badge-created'
                                                                : 'badge-pending' // Default class for "pending"
                                                        }`}
                                                >
                                                    {template.status || 'pending'}
                                                </span>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                // Show this row if no templates are available
                                <tr>
                                    <td colSpan="8" style={{ textAlign: 'center', color: 'gray' }}>
                                        No campaigns available
                                    </td>
                                </tr>
                            )}
                          
                        </tbody>

                    </table>
                </div>
            </div>
        </>
    );
};

export default TemplateList;
