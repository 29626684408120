import React, { useState } from 'react';
import './ButtonSection.css';

const ButtonSection = ({ onUpdateButtons }) => {
    const [buttons, setButtons] = useState([]);
    const [quickReplyDivs, setQuickReplyDivs] = useState([]);
    const [viewWebsiteCount, setViewWebsiteCount] = useState(0);
    const [isOfferCodeAdded, setIsOfferCodeAdded] = useState(false);
    const [dropdownValue, setDropdownValue] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // New state for error message

    const handleAddQuickReply = () => {
        setQuickReplyDivs([...quickReplyDivs, { inputText: '', isButtonAdded: false }]);
        setErrorMessage('');
    };

    const handleAddViewWebsite = () => {
        if (viewWebsiteCount < 2) {
            setQuickReplyDivs([...quickReplyDivs, { url: '', description: '', isButtonAdded: false }]);
            setViewWebsiteCount(viewWebsiteCount + 1);
            setErrorMessage('');
        }
    };

    const handleAddOfferCode = () => {
        if (!isOfferCodeAdded) {
            setQuickReplyDivs([...quickReplyDivs, { offerCode: '', input1: '', input2: '', isButtonAdded: false }]);
            setIsOfferCodeAdded(true);
            setErrorMessage('');
        }
    };

    const handleAddPhoneNumber = () => {
        setQuickReplyDivs([...quickReplyDivs, { phoneNumber: '', phoneText: '', isButtonAdded: false }]);
        setErrorMessage('');
    };

    const handleChange = (index, field, value) => {
        if (field === 'phoneNumber') {
            // Only allow input if it’s 10 digits or fewer
            const phoneRegex = /^[0-9]{0,10}$/;
            if (!phoneRegex.test(value)) return;
        }

        const updatedDivs = quickReplyDivs.map((div, i) =>
            i === index ? { ...div, [field]: value } : div
        );
        setQuickReplyDivs(updatedDivs);
    };

    const addButton = (index) => {
        const div = quickReplyDivs[index];

        // Check if phone number is exactly 10 digits
        if (div.phoneNumber && div.phoneNumber.length < 10) {
            setErrorMessage('Please enter a 10-digit phone number');
            return;
        }

        const newButton = {
            type: div.url
                ? 'URL'
                : div.offerCode
                    ? 'OFFER_CODE'
                    : div.phoneNumber
                        ? 'PHONE_NUMBER'
                        : 'QUICK_REPLY',
            text: div.inputText || div.description || div.phoneText || div.input1 || 'Default Text',
            ...(div.url && { url: div.url }),
            ...(div.phoneNumber && { phone_number: `+91${div.phoneNumber}` }), // Add +91 to phone number
            ...(div.offerCode && { offerCode: div.offerCode }),
        };

        const updatedButtons = [...buttons, newButton];
        setButtons(updatedButtons);
        onUpdateButtons(updatedButtons);

        const updatedDivs = quickReplyDivs.map((div, i) =>
            i === index ? { ...div, isButtonAdded: true } : div
        );
        setQuickReplyDivs(updatedDivs);
        setErrorMessage(''); // Clear error message on successful addition
    };

    const handleOptionSelect = (e) => {
        const selectedValue = e.target.value;

        if (selectedValue === 'Quick Reply') handleAddQuickReply();
        if (selectedValue === 'View Website') handleAddViewWebsite();
        if (selectedValue === 'Copy Offer Code') handleAddOfferCode();
        if (selectedValue === 'Phone Number') handleAddPhoneNumber();

        setDropdownValue('');
    };

    const removeDiv = (index) => {
        const removedDiv = quickReplyDivs[index];
        if (removedDiv.url) setViewWebsiteCount(viewWebsiteCount - 1);
        if (removedDiv.offerCode) setIsOfferCodeAdded(false);

        const updatedDivs = quickReplyDivs.filter((_, i) => i !== index);
        setQuickReplyDivs(updatedDivs);

        const updatedButtons = buttons.filter((_, i) => i !== index);
        setButtons(updatedButtons);
        onUpdateButtons(updatedButtons);
    };

    return (
        <div className="" style={{ width: '' }}>
            <h5 className="text11">
                Buttons <span className="optional">• Optional</span>
            </h5>
            <p>
                Create buttons that let customers respond to your message or take action. You can add up to 10
                buttons. If you add more than 3 buttons, they will appear in a list.
            </p>

     

            <div className="button-section">
                <div className="dropdown-container">
                    <label htmlFor="main-dropdown" className="dropdown-label">
                        Select an Option:
                    </label>
                    <select
                        id="main-dropdown"
                        className="dropdown"
                        value={dropdownValue}
                        onChange={handleOptionSelect}
                    >
                        <option value="">+ Add button</option>
                        <option value="Quick Reply">Quick Reply</option>
                        <option value="View Website" disabled={viewWebsiteCount >= 2}>
                            View Website
                        </option>
                        <option value="Copy Offer Code" disabled={isOfferCodeAdded}>
                            Copy Offer Code
                        </option>
                        <option value="Phone Number">Phone Number</option>
                    </select>
                </div>
                <br />  {errorMessage && <p className="error-message" style={{ textAlign: 'left', paddingLeft: '20px' }}>{errorMessage}</p>} 
                {quickReplyDivs.map((div, index) => (
                    <div key={index} className="additional-fields">
                        {/* <div className="close-button" onClick={() => removeDiv(index)}>
                            ×
                        </div> */}

                        {div.phoneNumber !== undefined ? (
                            <>
                                <input
                                    type="text"
                                    className="input-text-b"
                                    placeholder="Enter Phone Number (+CountryCode)"
                                    value={div.phoneNumber}
                                    onChange={(e) => handleChange(index, 'phoneNumber', e.target.value)}
                                    disabled={div.isButtonAdded}
                                />
                                <input
                                    type="text"
                                    className="input-text-b"
                                    placeholder="Enter Button Text"
                                    value={div.phoneText}
                                    onChange={(e) => handleChange(index, 'phoneText', e.target.value)}
                                    disabled={div.isButtonAdded}
                                />
                              
                            </>
                        ) : div.url !== undefined ? (
                            <>
                                <input
                                    type="text"
                                    className="input-text-b"
                                    placeholder="Enter URL"
                                    value={div.url}
                                    onChange={(e) => handleChange(index, 'url', e.target.value)}
                                    disabled={div.isButtonAdded}
                                />
                                <input
                                    type="text"
                                    className="input-text-b"
                                    placeholder="Enter Description"
                                    value={div.description}
                                    onChange={(e) => handleChange(index, 'description', e.target.value)}
                                    disabled={div.isButtonAdded}
                                />
                            </>
                        ) : (
                            <input
                                type="text"
                                className="input-text-b"
                                placeholder="Enter Quick Reply Text"
                                value={div.inputText}
                                onChange={(e) => handleChange(index, 'inputText', e.target.value)}
                                disabled={div.isButtonAdded}
                            />
                        )}

                        <div className="button-actions">
                            {div.isButtonAdded ? (
                                <button
                                    className="btn-danger"
                                    onClick={() => removeDiv(index)}
                                >
                                    Remove
                                </button>
                            ) : (
                                <button
                                    className="btn-primary"
                                    onClick={() => addButton(index)}
                                >
                                    Add Button
                                </button>
                                
                            )}
                        
                        </div>

                    </div>
                                 
                ))}
                
            </div>
        </div>
    );
};

export default ButtonSection;
