const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ENDPOINTS = {
    LOGIN: `${BASE_URL}/auth/login`, // Login endpoint
    // GET_WATEMPLATES: (userId, page = 1) => `${BASE_URL}/wa/get_wa_templates/${userId}?page=${page}&limit=10`,
    GET_CAMP_TEMPLATES: ( page = 1) => `${BASE_URL}/templates/get-templates/?page=${page}&limit=10`,
    CREATE_TEMPLATE: `${BASE_URL}/templates/create-template`,
    UPLOAD_MEDIA: `${BASE_URL}/wa/send_uploadMedia`,
    SEND_MESSAGE: `${BASE_URL}/webhook/send-message`,
    // GET_USER_TEMPLATES: ( page = 1, limit = 10) =>
    //     `${BASE_URL}/wa/get_wa_templates/?page=${page}&limit=${limit}`,
    GET_WA_AP_TEMPLATES: `${BASE_URL}/wa/get_wa_ap_templates`,
    CREATE_WA_TEMPLATE: `${BASE_URL}/wa/create_wa_template`,
    FETCH_REPORT: (templateId) => `${BASE_URL}/wa/report/${templateId}`,
    FETCH_STATUS: (templateId, status) =>
        `${BASE_URL}/wa/reportstatus/${templateId}?status=${status}&page=1&limit=10`,
    FETCH_TEMPLATES: (page) => `${BASE_URL}/wa/get_wa_templates/?page=${page}`,
    GET_WA_TEMPLATE_COUNTS: `${BASE_URL}/wa/get_wa_template_counts`,
    TEMPLATE_MAPPING: `${BASE_URL}/wamap/template-wa-mapping`, 
    USER_BALANCE: `${BASE_URL}/user/balance`,
    GET_INCOMING_MESSAGES: (page = 1, limit = 10) =>
        `${BASE_URL}/wa/get_incoming_messages?page=${page}&limit=${limit}`,

};
