import React from 'react';
import underConstruction from '../assets/cons.gif'; // Adjust path as necessary
import './Manager.css'; // Import the CSS file

const Manager = () => {
    return (
        <div className="construction-container">
            <img src={underConstruction} alt="Under Construction" className="construction-image" />
            <h1 className="construction-heading">Something Exciting is Coming Soon!</h1>
            <p className="construction-message">
                Our team is crafting a fantastic experience for you. Stay tuned and get ready to be amazed!
            </p>
        </div>
    );
};

export default Manager;
