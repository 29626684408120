import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../ApiService/ApiService';
import { jwtDecode } from 'jwt-decode';
import './Login.css';
import loginImage from '../../assets/images/webbi.png';
import logoImage from '../../assets/whatsapp-logo.svg';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        setError('');
        setLoading(true); // Set loading to true

        const payload = { email, password };

        loginUser(payload)
            .then((data) => {
                console.log('Login Success:', data);

                // Store the token in local storage
                localStorage.setItem('token', data.token);

                // Decode the token and get user ID
                const token = data.token;
                const decoded = jwtDecode(token);
                const userId = decoded.userId;
                console.log('User ID:', userId);

                // Navigate to main layout
                navigate('/main');
            })
            .catch((err) => {
                console.error('Login Error:', err.message);
                setError(err.message);
            })
            .finally(() => {
                setLoading(false); // Reset loading state
            });
    };

    return (
        <div className="login1">
            <div className="logo-container">
                <img src={logoImage} alt="Logo" className="logo-image" />
                <h1 className="site-name">iissms-whatsapp</h1>
            </div>
            <div className="login-container">
                <div className="login-right">
                    <h2 className="login-title">Let's you sign in</h2>
                    <p className="login-subtitle">
                        Welcome to our Page <a href="/register">Sign Up</a>
                    </p>
                    <form onSubmit={handleLogin} className="login-form">
                        <div className="form-grouplogin">
                            <label>Username:</label>
                            <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                autoComplete="email"
                            />
                        </div>
                        <div className="form-grouplogin">
                            <label>Password:</label>
                            <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                autoComplete="current-password"
                            />
                        </div>
                        <div className="form-grouplogin form-check">
                            <input type="checkbox" className="form-check-input" id="keepLoggedIn" />
                            <label className="form-check-label" htmlFor="keepLoggedIn">
                                Keep me logged in
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="btn1 btn-block"
                            disabled={loading} // Disable button while loading
                        >
                            {loading ? 'Signing in...' : 'Sign In'}
                        </button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
                <div className="login-left">
                    <img src={loginImage} alt="Login Illustration" className="login-image" />
                </div>
            </div>
        </div>
    );
};

export default Login;
