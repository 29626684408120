import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'; // Import the Breadcrumbs component
import { ThemeProvider } from 'react-bootstrap';
import './MainLayout.css';

const MainLayout = React.memo(() => {
    const layoutStyle = useMemo(() => ({
        background: '',
        zIndex: '998',
    }), []);

    const contentStyle = useMemo(() => ({
        display: 'flex',
    }), []);

    return (
        <ThemeProvider>
            <div className='mainl' style={layoutStyle}>
                <Sidebar />
                <div style={contentStyle} className='main-layout'>
                    <Navbar /> 
                    <div className='main-content'>
                        <Breadcrumbs /> {/* Add Breadcrumbs here */}
                        <Outlet />
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
});

export default MainLayout;
