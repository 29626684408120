import React from 'react';
import './Chatbox.css'; // Add styling as needed
import { FaImage, FaFilePdf, FaMapMarkerAlt } from 'react-icons/fa'; // Import necessary icons

const Chatbox = ({
    headerType, // New prop for checking the header type
    headerText,
    headerVariables,
    bodyText,
    bodyVariables,
    footerText,
    buttonText,
    headerImage, // New prop for the image
}) => {
    // Log headerType to check its value
    console.log("Header Type:", headerType);

    // Replace {{n}} placeholders with corresponding variables in both header and body
    const replacePlaceholders = (text, variables) => {
        let replacedText = text;
        variables.forEach((variable, index) => {
            replacedText = replacedText.replace(`{{${index + 1}}}`, variable || `{{${index + 1}}}`);
        });
        return replacedText;
    };

    // Render header based on headerType
    const renderHeader = () => {
        switch (headerType) {
            case 'IMAGE':
                return headerImage ? (
                    <img src={headerImage} alt="Header" className="chat-header-image" />
                ) : (
                    <FaImage title="Image header" className="chat-header-icon" style={{ marginLeft: '10px' }} />
                );
            case 'DOCUMENT':
                return <FaFilePdf title="Document header" className="chat-header-icon" style={{ marginLeft: '10px' }} />;
            case 'LOCATION':
                return <FaMapMarkerAlt title="Location header" className="chat-header-icon" style={{ marginLeft: '10px' }} />;
            default:
                return headerText && <div>{replacePlaceholders(headerText, headerVariables)}</div>;
        }
    };

    return (
        <div className="chadiv1">
            <div className="chatboxmod">
                <div className="chat-headermod">
                    {/* Render header based on the headerType */}
                    {renderHeader()}
                </div>
                <div className="chat-bodymod">{replacePlaceholders(bodyText, bodyVariables)}</div>
                <div className="chat-footermod">{footerText}</div>
            </div>
            <div className="chat-buttons12mod">
                {buttonText && (
                    <button className="chat-button1mod">{buttonText}</button>
                )}
            </div>
        </div>
    );
};

export default Chatbox;
