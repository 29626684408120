import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import TokenService from '../Tokens/tokenservice';
import { ENDPOINTS } from './endpoints';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// export const loginUser = (payload) => {
//     return axios.post(ENDPOINTS.LOGIN, payload)
//         .then((response) => {
//             // Handle success
//             return response.data;
//         })
//         .catch((error) => {
//             // Handle errors
//             if (error.response) {
//                 // Server responded with an error
//                 throw new Error(error.response.data.message || 'Login failed');
//             } else if (error.request) {
//                 // No response received
//                 throw new Error('No response from the server');
//             } else {
//                 // Other errors
//                 throw new Error(error.message);
//             }
//         });
// };


// export const loginUser = (payload) => {
//     return axios.post(ENDPOINTS.LOGIN, payload, { 
//         credentials: "include" 
//     })
//     .then((response) => {
//         // Handle success
//         return response.data;
//     })
//     .catch((error) => {
//         // Handle errors
//         if (error.response) {
//             // Server responded with an error
//             throw new Error(error.response.data.message || 'Login failed');
//         } else if (error.request) {
//             // No response received
//             throw new Error('No response from the server');
//         } else {
//             // Other errors
//             throw new Error(error.message);
//         }
//     });
// };



// Login User and Store Token in localStorage
export const loginUser = (payload) => {
    return axios
        .post(ENDPOINTS.LOGIN, payload, {
            credentials: "include",
        })
        .then((response) => {
            const token = response.data.token;

            if (token) {
                TokenService.setToken(token); // Store the token in localStorage
                console.log('Token stored successfully!');
                return response.data;
            } else {
                throw new Error('No token received from the server.');
            }
        })
        .catch((error) => {
            if (error.response) {
                throw new Error(error.response.data.message || 'Login failed');
            } else if (error.request) {
                throw new Error('No response from the server');
            } else {
                throw new Error(error.message);
            }
        });
};

// Helper Function to Get Token and Check Validity
 // Assuming TokenService is your custom service
// Assuming TokenService is your custom service

export const getToken = () => {
    const token = TokenService.getToken(); // Retrieve the token from localStorage

    if (!token) {
        console.error('Token is invalid or expired. Redirecting to login...');
        return <Navigate to="/login" replace />; // Redirect to login if token is invalid
    }

    try {
        const decodedToken = jwtDecode(token); // Decode the token to get the payload
        const expiryTime = decodedToken.exp * 1000; // Convert expiry time from seconds to milliseconds
        const currentTime = Date.now(); // Get current time in milliseconds

        if (currentTime >= expiryTime) {
            console.error('Token has expired. Redirecting to login...');
            return <Navigate to="/login" replace />; // Redirect to login if token has expired
        }

        console.log('Token is valid. Expires at:', new Date(expiryTime).toLocaleString());
    } catch (error) {
        console.error('Error decoding token:', error);
        return <Navigate to="/login" replace />; // Redirect if decoding fails
    }

    return token; // Return the valid token
};



// Handle API errors globally
const handleApiError = (error) => {
    if (error.response) {
        console.error(`Error: ${error.response.status} - ${error.response.data.message}`);
        if (error.response.status === 401 || error.response.status === 403) {
            window.location.href = '/home';
        }
    } else if (error.request) {
        console.error('Network error: No response from server');
    } else {
        console.error('Error', error.message);
    }
};

// Fetch templates
export const fetchTemplates = async ({ page }) => {
    try {
       
        const token = getToken();

        // Debugging: Log the userId and the endpoint URL
      
        console.log('Endpoint:', ENDPOINTS.GET_CAMP_TEMPLATES( page));

        const response = await api.get(ENDPOINTS.GET_CAMP_TEMPLATES(page), {
            headers: { Authorization: `Bearer ${token}` },
        });

        return response.data.templates || [];
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};


// Create a new template
export const createTemplate = async (templateName, templateDescription) => {
    try {
     
        const token = await getToken();
        const response = await api.post(
            ENDPOINTS.CREATE_TEMPLATE,
            {
                name: templateName,
                description: templateDescription,
             
            },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data.template;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

// Upload media
export const uploadMedia = async (file) => {
    try {
        const formData = new FormData();
        formData.append('mediaFile', file);
        const token = await getToken();
        const response = await api.post(ENDPOINTS.UPLOAD_MEDIA, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

// Send message
export const sendMessage = async (payload) => {
    try {
        const token = await getToken();
        const response = await api.post(ENDPOINTS.SEND_MESSAGE, payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

// Fetch user templates
export const fetchUserTemplates = async (page = 1, limit = 10) => {
    try {
      
        const token = await getToken();
        const response = await api.get(ENDPOINTS.GET_USER_TEMPLATES( page, limit), {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.templates || [];
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

// Get WhatsApp AP templates
export const getWAApTemplates = async () => {
    try {
     
        const token = await getToken();
        const response = await api.get(ENDPOINTS.GET_WA_AP_TEMPLATES, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

// Create WhatsApp template
export const createWATemplate = async (jsonData) => {
    try {
        const token = await getToken();
        const response = await api.post(ENDPOINTS.CREATE_WA_TEMPLATE, jsonData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

// Fetch report data
export const fetchReportData = async (templateId) => {
    try {
        const token = await getToken();
        const response = await api.post(
            ENDPOINTS.FETCH_REPORT(templateId),
            {},
            { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

// Fetch status data
export const getStatusData = async (templateId, status) => {
    try {
        const token = await getToken();
        const response = await api.post(
            ENDPOINTS.FETCH_STATUS(templateId, status),
            {},
            { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
    } catch (error) {
        handleApiError(error);
        throw error;
    }
};

export const fetchTemplatesAPI = async (userId, token, page = 1) => {
    const url = ENDPOINTS.FETCH_TEMPLATES( page); // Use the imported endpoint

    try {
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
    } catch (error) {
        console.error("Error in fetchTemplatesAPI:", error);
        throw error;
    }
};


export const fetchTemplateCounts = async () => {
  
    try {
        const token = await getToken();
        const response = await api.get(ENDPOINTS.GET_WA_TEMPLATE_COUNTS, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (response.data && Array.isArray(response.data.templateCounts)) {
            return response.data.templateCounts; // Return the template counts array
        }
        return []; // Return an empty array if data is not as expected
    } catch (error) {
        console.error('Error fetching template counts:', error);
        throw new Error('Failed to fetch template counts');
    }
};

export const postTemplateMapping = async (templateId, waTemplateId, userId) => {
    try {
        const response = await axios.post(
            ENDPOINTS.TEMPLATE_MAPPING,
            {
                template_id: templateId,
                wa_template_id: waTemplateId,
                userid: userId,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error in postTemplateMapping API:', error);
        throw error;
    }
};


export const getUserBalance = async () => {
    try {
        const token = getToken(); // Get the token (this handles expiry checks and redirects)
        if (!token) {
            throw new Error('Token is not available or expired. Redirecting to login...');
        }

        // Make the POST request with only the token in the header
        const response = await axios.post(ENDPOINTS.USER_BALANCE, {}, {
            headers: {
                Authorization: `Bearer ${token}`, // Attach the token in the Authorization header
            },
        });

        return response.data; // Return the balance data from the response
    } catch (error) {
        console.error('Error fetching user balance:', error.message);
        throw error;
    }
};

// Fetch Incoming Messages
export const getIncomingMessages = async (page = 1, limit = 10) => {
    try {
        const token = getToken(); // Retrieve the token using the helper function

        if (!token) {
            console.error('Token is invalid or expired. Unable to proceed with the API request.');
            return []; // Return an empty array if token is invalid/expired
        }

        // Ensure that the Authorization header contains a valid token
        const response = await api.post(ENDPOINTS.GET_INCOMING_MESSAGES(page, limit), {}, {
            headers: {
                Authorization: `Bearer ${token}`, // Add token to the Authorization header
            },
        });

        // Check if the response contains the 'messages' data
        return response.data.messages || []; // Return messages or empty array if no messages found
    } catch (error) {
        handleApiError(error); // Handle API errors globally
        throw error; // Rethrow the error to allow for further handling upstream
    }
};