import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './AdvBar.css';
import { faker } from '@faker-js/faker';
import { useTheme } from '../ThemeContext';
import { createTemplate, fetchTemplates } from '../ApiService/ApiService'; // Import createTemplate and fetchTemplates functions from apiService
import { getToken } from '../Tokens/tokenservice'; // Import getToken from tokenService

const Modal = ({ message, showModal, onClose, isSuccess }) => {
    if (!showModal) return null;

    return (
        <div className="modal-backdroptl">
            <div className="modal-contenttl">
                <div className="modal-bodytl">
                    {!isSuccess ? (
                        <div className="loading-spinnertl"></div>
                    ) : (
                        <div className="checkmark-containertl">
                            <div className="checkmarktl"></div>
                        </div>
                    )}
                    <h2>{message}</h2>
                </div>
                {isSuccess && (
                    <button onClick={onClose} className="close-btntl">
                        Close
                    </button>
                )}
            </div>
        </div>
    );
};

const AdvBar = () => {
    const { theme } = useTheme(); // Use the theme from context
    const [templates, setTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [modalMessage, setModalMessage] = useState('Processing...'); // State for modal message
    const [isSuccess, setIsSuccess] = useState(false); // State for success indicator
    const navigate = useNavigate();

    // Fetch templates from apiService
 

    const handleCreateTemplate = async () => {
        // const templateName = `Template ${faker.word.adjective()} ${faker.word.noun()}`;
        // const templateDescription = faker.lorem.sentence(); // Generate a random description
        const categories = ["Marketing", "Sales", "Promotion", "Feedback", "Welcome", "Reminder", "Support", "Survey"];

        // Pick a random noun from the list
        const templateName = `Template ${categories[Math.floor(Math.random() * categories.length)]}`;

        // Create a relevant description based on the selected category
        const templateDescription = `This template is ideal for ${templateName.toLowerCase().replace("template ", "")} campaigns.`;

        setShowModal(true); // Show the modal with "Processing..."
        setModalMessage('Processing...');
        setIsSuccess(false); // Start with loading animation

        try {
            const response = await createTemplate(templateName, templateDescription); // Use the createTemplate from apiService
            const templateId = response.id;

            // Simulate a 2-second delay before showing success message
            setTimeout(() => {
                setModalMessage('Campaign created successfully!');
                setIsSuccess(true); // Show checkmark on success
                setTimeout(() => {
                    setShowModal(false); // Close modal after success message
                    navigate(`/main/send-template?template_id=${templateId}`);
                }, 1500); // Close after 1.5 seconds
            }, 2000); // Simulate 2 seconds for processing

           
        } catch (error) {
            console.error('Error creating new template:', error);
            setModalMessage('Failed to create a new template. Please try again later.');
            setTimeout(() => {
                setShowModal(false); // Close modal on failure
            }, 2000);
        }
    };

    return (
        <>
            <div className="box" id='advertise'>
                <h1 className="headingadv">WELCOME TO IISSMS</h1>
                <button onClick={handleCreateTemplate} className="create-template-button">
                    Create Campaign
                </button>
            </div>
            <Modal message={modalMessage} showModal={showModal} onClose={() => setShowModal(false)} isSuccess={isSuccess} />
        </>
    );
};

export default AdvBar;
