import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import MainLayout from './components/MainLayout/MainLayout';
import Login from './components/Login/LogIn';
import Register from './components/Register/Register';
import Content1 from './components/Content1';

import { ThemeProvider } from './components/ThemeContext';
import SelectTemplate from './components/SelectTemplate/SelectTemplate';
import CreateWhatsappTemplate from './components/CreateWhatsappTemplate/CreateWhatsappTemplate';
import ModifyTemplate from './components/ModifyTemplate/ModifyTemplate';
import TemplateReport from './components/TemplateReport/TemplateReport';
import Summary from './components/Summary/Summary';
import FileUpload from './components/FileUpload';
import Authotp from './components/Authotp/Authotp';
import NextComponent from './components/NextComponent/NextComponents';
// import Catalog from './components/Catalog/Catalog';
// import CreateFlow from './components/Flows/CreateFlow';
// import FlowTemplate from './components/Flows/FlowTemplate';
import CreateTemplate from './components/NextComponent/CreateTemplate';
import Manager from './components/Manager';
import Manager1 from './components/Manager1';
import Manager2 from './components/Manager2';
import Manager3 from './components/Manager3';
import Dashboard from './components/Dashboard/Dashboard';
import TemplatePreviews from './components/TemplatesPre/TemplatePreviews';
import CustomUtility from './components/NextComponent/CustomUtility';
import Messages from './components/Messages/Messages'
const isTokenValid = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp > currentTime; // Check if the token is still valid
    } catch (error) {
        console.error('Error decoding token:', error);
        return false;
    }
};

const PrivateRoute = ({ element: Component }) => {
    return isTokenValid() ? <Component /> : <Navigate to="/login" />;
};

const AppRouter = () => {
    const tokenValid = isTokenValid();

    return (
        <ThemeProvider>
            <Router>
                <Routes>
                    {/* Redirect to main if token is valid, else redirect to login */}
                    <Route path="/" element={<Navigate to={tokenValid ? "/main/dashboard" : "/login"} />} />
                    {/* <Route path="/" element={<Navigate to={tokenValid ? "/main/SelectTemplate" : "/login"} />} /> */}
                    {/* Public routes */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />

                    {/* Protected routes */}
                    <Route path="/main" element={<PrivateRoute element={MainLayout} />}>
                        {/* Redirect to Content1 if no specific route is provided */}
                        <Route index element={<Navigate to="dashboard" />} />
                        <Route path="Home" element={<Content1 />} />

                        <Route path="create-template" element={<SelectTemplate />} />
                        <Route path="send-template" element={<CreateWhatsappTemplate />} />
                        <Route path="template_report" element={<TemplateReport />} />
                        <Route path="summary" element={<Summary />} />
                        <Route path="authotp" element={<Authotp />} />
                        <Route path="createmplate" element={<NextComponent />} />
                        {/* <Route path="utility-custom" element={<Authcustom />} /> */}
                        {/* <Route path="catalogue" element={<Catalog />} />
                        <Route path="create_flow_template" element={<FlowTemplate />} /> */}
                        <Route path="Create_Template" element={<CreateTemplate />} />
                        <Route path="utility_custom_template" element={<CustomUtility />} />
                        <Route path="site_under_construction" element={<Manager />} />
                        <Route path="under_construction" element={<Manager1 />} />
                        <Route path="site_construction" element={<Manager2 />} />
                        <Route path="site_comming_soon" element={<Manager3 />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="templates" element={<TemplatePreviews />} />
                        <Route path="messages" element={<Messages />} />
                    </Route>
                    <Route path="/template-page" element={<PrivateRoute element={SelectTemplate} />} />

                    <Route path="modify_Whatsapp_Template" element={<PrivateRoute element={ModifyTemplate} />} />
                    {/* <Route path="/create_whatsapp_template" element={<PrivateRoute element={CreateWhatsappTemplate} />} /> */}
                    <Route path="upload_file" element={<PrivateRoute element={FileUpload} />} />
                    {/* <Route path="create_flow" element={<PrivateRoute element={CreateFlow} />} /> */}


                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default AppRouter;
