import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({ data, colors, labels }) => {
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: colors,
                hoverBackgroundColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
        },
    };

    return <Doughnut data={chartData} options={options} />;
};

export default DoughnutChart;
