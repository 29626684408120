import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumbs.css';
import { faker } from '@faker-js/faker';
import { createTemplate, fetchTemplates } from '../ApiService/ApiService';
import { useNavigate } from 'react-router-dom';
const Breadcrumbs = () => {
    const location = useLocation();
    const [showModal, setShowModal] = useState(false); // State for modal visibility
    const [modalMessage, setModalMessage] = useState('Processing...'); // State for modal message
    const [isSuccess, setIsSuccess] = useState(false); // State for success indicator
    const navigate = useNavigate();
  
    // Split the current path into parts and filter out 'main'
    const pathnames = location.pathname
        .split('/')
        .filter((x) => x && x !== 'main'); // Exclude 'main'


    const Modal = ({ message, showModal, onClose, isSuccess }) => {
        if (!showModal) return null;

        return (
            <div className="modal-backdroptl">
                <div className="modal-contenttl">
                    <div className="modal-bodytl">
                        {!isSuccess ? (
                            <div className="loading-spinnertl"></div>
                        ) : (
                            <div className="checkmark-containertl">
                                <div className="checkmarktl"></div>
                            </div>
                        )}
                        <h2>{message}</h2>
                    </div>
                    {isSuccess && (
                        <button onClick={onClose} className="close-btntl">
                            Close
                        </button>
                    )}
                </div>
            </div>
        );
    };

    const handleCreateTemplate = async () => {
        const templateName = `Template ${faker.word.adjective()} ${faker.word.noun()}`;
        const templateDescription = faker.lorem.sentence(); // Generate a random description
        setShowModal(true); // Show the modal with "Processing..."
        setModalMessage('Processing...');
        setIsSuccess(false); // Start with loading animation

        try {
            const response = await createTemplate(templateName, templateDescription); // Use the createTemplate from apiService
            const templateId = response.id;

            // Simulate a 2-second delay before showing success message
            setTimeout(() => {
                setModalMessage('Campaign created successfully!');
                setIsSuccess(true); // Show checkmark on success
                setTimeout(() => {
                    setShowModal(false); // Close modal after success message
                    navigate(`/main/send-template?template_id=${templateId}`);
                }, 1500); // Close after 1.5 seconds
            }, 2000); // Simulate 2 seconds for processing


        } catch (error) {
            console.error('Error creating new template:', error);
            setModalMessage('Failed to create a new template. Please try again later.');
            setTimeout(() => {
                setShowModal(false); // Close modal on failure
            }, 2000);
        }
    };

    return (
        <nav className="breadcrumbs" style={{ marginTop: '50px', padding: '20px 20px 0px 20px' }}>
            <ul>
                {/* Home breadcrumb */}
                <li>
                    <Link to="/">IISMS-Whatsapp</Link>
                </li>
                <li>>></li>
                {pathnames.map((value, index) => {
                    const to = `/${pathnames
                        .slice(0, index + 1)
                        .filter((x) => x !== 'main') // Ensure 'main' is excluded
                        .join('/')}`;

                    return (

                        <li key={to}>
                            {index === pathnames.length - 1 ? (
                                <span>{decodeURIComponent(value)}</span> // Current page as text
                            ) : (
                                <Link to={to}>{decodeURIComponent(value)}</Link>
                            )}
                        </li>

                    );
                })}
                {/* <li>
                    <button onClick={handleCreateTemplate} className="create-template-button">
                        Create Campaign
                    </button>
                </li> */}
            </ul>

        </nav>
    );
};

export default Breadcrumbs;
