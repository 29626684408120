import React from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './StatCard.css';
import CampaignComparisonChart from '../Charts/CampaignComparisonChart';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const StatCard = () => {
    const lineChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: { grid: { display: false } },
            y: { grid: { display: false } },
        },
    };

    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Revenue',
                data: [50, 75, 150, 100, 200, 175],
                backgroundColor: 'rgba(54, 162, 235, 0.3)',
                borderColor: '#007bff',
                borderWidth: 2,
                fill: true,
            }
        ]
    };

    const doughnutChartData = {
        labels: ['Completed', 'Remaining'],
        datasets: [
            {
                data: [75, 25],
                backgroundColor: ['#007bff', 'rgba(54, 162, 235, 0.3)'],
                borderWidth: 0,
            }
        ]
    };

    const doughnutChartOptions = {
        maintainAspectRatio: false,
        rotation: -90, // Start angle for the semi-pie
        circumference: 180, // Only render a half-circle (semi-pie)
        plugins: {
            legend: { display: false },
        },
        cutout: '70%', // Creates the hollow center for a semi-pie appearance
    };

    return (
        <div className="chart-container" >
            <div className="line-chart">
                <Line data={lineChartData} options={lineChartOptions}  />
            </div>
         <div className="doughnut-chart">
                {/* <Doughnut data={doughnutChartData} options={doughnutChartOptions} /> */}
                <CampaignComparisonChart />
            </div> 
            {/* <div className="box box1" id='tabsicar'>
              

            </div> */}
        </div>
    );
};

export default StatCard;
