import React, { useEffect, useState } from "react";
import {jwtDecode} from "jwt-decode";
import ChatPreview from "./TemplateCard"; // Ensure the path is correct
import { fetchTemplatesAPI } from "../ApiService/ApiService"; // Import the API service
import "./TemplatePreviews.css";

const SkeletonCard = () => (
    <div className="skeleton-card">
        <div className="skeleton-image"></div>
        <div className="skeleton-title"></div>
        <div className="skeleton-text"></div>
    </div>
);

const TemplatePreviews = () => {
    const [templates, setTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const getUserIdFromToken = () => {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const decoded = jwtDecode(token);
                return decoded.id;
            } catch (err) {
                console.error("Error decoding token:", err);
            }
        }
        return null;
    };

    const fetchTemplates = async (page = 1) => {
        const userId = getUserIdFromToken();
        const token = localStorage.getItem("token");

        if (!userId || !token) {
            setError("Missing userId or token");
            setLoading(false);
            return;
        }

        try {
            const data = await fetchTemplatesAPI(userId, token, page); // Use the API service function
            if (page === 1) {
                setTemplates(data.templates || []);
                const uniqueCategories = [
                    ...new Set(data.templates.map((template) => template.wa_template_category)),
                ];
                setCategories(uniqueCategories);
            } else {
                setTemplates((prevTemplates) => [...prevTemplates, ...(data.templates || [])]);
            }
            setHasMore(data.templates.length > 0);
        } catch (error) {
            console.error("Error fetching templates:", error);
            setError("Failed to fetch templates");
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    useEffect(() => {
        const filtered = templates.filter((template) => {
            const matchesSearch = template.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = selectedCategory
                ? template.wa_template_category === selectedCategory
                : true;
            return matchesSearch && matchesCategory;
        });
        setFilteredTemplates(filtered);
    }, [searchTerm, selectedCategory, templates]);

    const handleLoadMore = () => {
        setLoadingMore(true);
        setCurrentPage((prevPage) => prevPage + 1);
        fetchTemplates(currentPage + 1);
    };

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <>
            <h4 style={{ margin: "20px", color: "#00417d", position: "sticky" }}>
                IISMS-Manager <span> &gt; </span> All Templates
            </h4>
            <div className="box searfil">
                <input
                    type="text"
                    placeholder="Search templates..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-bar"
                />
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="category-filter"
                >
                    <option value="">All Categories</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category}>
                            {category}
                        </option>
                    ))}
                </select>
            </div>

            <div className="template-previews-container">
                {loading ? (
                    Array.from({ length: 6 }).map((_, index) => <SkeletonCard key={index} />)
                ) : filteredTemplates.length > 0 ? (
                    filteredTemplates.map((template) => (
                        <div key={template.id}>
                            <ChatPreview template={template} />
                        </div>
                    ))
                ) : (
                    <p>No templates available</p>
                )}
            </div>
            {hasMore && (
                <div className="load-more-container">
                    <button
                        onClick={handleLoadMore}
                        className="load-more-button"
                        disabled={loadingMore}
                    >
                        {loadingMore ? "Loading..." : "Load More"}
                    </button>
                </div>
            )}
        </>
    );
};

export default TemplatePreviews;
