import React from "react";
import "./TemplateCard.css";

// Import Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faFileAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const TemplateCard = ({ template }) => {
    let parsedCode = {};
    try {
        // Parse the `code` JSON string into an object
        parsedCode = JSON.parse(template.code);
    } catch (error) {
        console.error("Invalid JSON in template.code:", error);
    }

    const { name, components = [] } = parsedCode;

    const headerComponent = components.find(comp => comp.type === "HEADER");
    const bodyComponent = components.find(comp => comp.type === "BODY");
    const footerComponent = components.find(comp => comp.type === "FOOTER");
    const buttonComponent = components.find(comp => comp.type === "BUTTONS");

    const headerType = headerComponent ? headerComponent.format : null;
    const headerText = headerComponent && headerType === "TEXT" ? headerComponent.text : null;
    const bodyText = bodyComponent ? bodyComponent.text : null;
    const footerText = footerComponent ? footerComponent.text : null;
    const buttons = buttonComponent ? buttonComponent.buttons : [];

    // Function to get the icon for header types
    const getHeaderIcon = (type) => {
        switch (type) {
            case "IMAGE":
                return <FontAwesomeIcon icon={faImage} className="header-icon" />;
            case "DOCUMENT":
                return <FontAwesomeIcon icon={faFileAlt} className="header-icon" />;
            case "LOCATION":
                return <FontAwesomeIcon icon={faMapMarkerAlt} className="header-icon" />;
            default:
                return null;
        }
    };

    return (
        <div className="template-card">
            <div className="box chadiv">
                <div className="chatboxmod">
                    <div className="chat-headermod">
                        {headerText ? (
                            <span>{headerText}</span>
                        ) : (
                            getHeaderIcon(headerType) 
                        )}
                    </div>
                    <div className="chat-bodymod">{bodyText || "No Body"}</div>
                    <div className="chat-footermod">{footerText || ""}</div>
                </div>
                <div className="chat-buttons12mod">
                    {buttons.map((button, index) => (
                        <button key={index} className="chat-button1mod">
                            {button.text}
                        </button>
                    ))}
                </div>
            </div>
            <div className="template-name">
                <h3 className="template-title" data-title={name || "Unnamed Template"}>
                    {name || "Unnamed Template"}
                </h3>
                <div className="typtwocat">
                <p className="catmautau"> {template.wa_template_category || "Unknown"}</p>
                    <p className={`status ${template.wa_template_status || 'unknown'}`}>
                        {template.wa_template_status || 'Unknown'}
                    </p>

                </div>
            </div>
        </div>
    );
};

export default TemplateCard;
